<template>
    <div class="vx-row flex">
          <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-2 hover:text-primary cursor-pointer" @click="editRecord" />
          <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-2 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
          <feather-icon icon="FileTextIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="downloadInvoice" />
      </div>
</template>

<script>
import recipe from "@/recipe";

export default {
  name: 'CellRendererActions',
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    }
  },
  methods: {
    downloadInvoice () {
      let a = document.createElement('a');
      a.href = `${recipe}/api/v1/invoice-customers/${this.params.data.id}/print-invoice`;
      a.setAttribute('download', true);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    showData () {
      this.$router.push({name: 'invoice-customer-show', params: { id: this.encrypt }});
    },
    editRecord () {
      this.$router.push({name: 'invoice-customer-edit', params: { id: this.encrypt }});
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want to delete it ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("invoiceCustomer/removeItem", this.params.data.id)
          .then((resp)   => {

            this.params.getData();

            this.showDeleteSuccess(resp)
          })
          .catch(err => {
            this.showDeleteError(err)
          })
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }

  }
}
</script>
